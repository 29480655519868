// MapMarker Icon
var icon = '/media/images/map_marker@2x.png';

$(function() {
  if(typeof(flush_cart) !== 'undefined' && flush_cart) {
    setTimeout(function() {
      DefaultAdapter.emptyCart();
    },1);
  }

  $(function () {
    $('[data-toggle="popover"]').popover()
  });

  $('.dropdown').hide();

  $('.dropdown-toggle').click(function(e) {
    e.preventDefault();

    var $parent = $(this).parent();
    if($('.dropdown',$parent).is(':visible')) {
      $parent.removeClass('active');

      $('.dropdown',$parent).hide();
    }
    else {
      $parent.addClass('active');

      $('.dropdown',$parent).show();

      setTimeout(function() {
        $( document ).one( "click", function() {
          $('.dropdown-toggle',$parent).click();
        });
      },1);
    }
  });

  $('.sub-nav-select').on('change',function() {
   $('#submit-select').click();
 });



  $('.toggle_nav').click(function(e) {

    e.preventDefault();

    $('.mobile_menu').toggleClass('active');
    $('body').toggleClass('no_scroll');
    $(this).toggleClass('active').blur();
  });

  initJumpNav();
  $('.video').click(function(e) {
    e.preventDefault();
    $(this).addClass('active');
  });

  $('.load_more_posts').click(function(e){
    e.preventDefault();
    var $$ = $(this);
    var next_page = $(this).data('next');
    var page_id = $(this).data('page_id');

    if(typeof(page_id) == 'undefined' || page_id == '') page_id =2;


    $.ajax(next_page + '?page=' + page_id)
    .done(function(data) {
      $$.data('page_id',page_id+1);
      var posts = $(data.items).find('.post_wrap_grid');

      $('.posts-backgound').append(posts);
      if(!data.more_pages) {
        $$.hide();
      }


    });

  });

  initImage();

  $('#cookie_notice').click(function(e) {
    e.preventDefault();
    $(".banner-menu").removeClass('actives');
    $(".mobile_menu").removeClass('banner-active');
    $(".banner-cookie p").remove();
    $(".banner-cookie h3").remove();
    $(".spacer").removeClass('active-sp');
    Cookie.storeData();


  });

  Cookie.init();
  initImageReel();
});




function initImageReel() {
  $('.image_reel').each(function(i,itm) {
    calcReel($(this));
  });

  $(window).resize(function() {
    $('.image_reel').each(function(i,itm) {
      calcReel($(this));
    });
  });
}

function calcReel($el) {
  var div_width = $el.find('.breakout').width() ;

  var animation_id = $el.data('animation_id');
  if(!animation_id) {
    animation_id =  'reel_'+cuniq();
  }
  $el.data('animation_id',animation_id);

  var $img = $('.breakout img',$el);
  var duration = $img.data('duration');

     // Create a new <img>
     var img_width = $img.width() ;
     var max_move = img_width-div_width;



     $.keyframe.define([{
      name: animation_id,
      '100%': {transform:"translateX(-"+max_move+"px)"}
    }]);

     $img.playKeyframe({
          name: animation_id, // name of the keyframe you want to bind to the selected element
          duration: duration+'s', // [optional, default: 0, in ms] how long you want it to last in milliseconds
          timingFunction: 'ease-in-out', // [optional, default: ease] specifies the speed curve of the animation
          delay: '0s', //[optional, default: 0s]  how long you want to wait before the animation starts
          iterationCount: 'infinite', //[optional, default:1]  how many times you want the animation to repeat
          direction: 'alternate', //[optional, default: 'normal']  which direction you want the frames to flow
          fillMode: 'forwards', //[optional, default: 'forward']  how to apply the styles outside the animation time, default value is forwards
        });

   }

   var c = 1;
   function cuniq() {
    var d = new Date(),
    m = d.getMilliseconds() + "",
    u = ++d + m + (++c === 10000 ? (c = 1) : c);
    return u;
  }

  function initImage() {
    $('.product_product_image>div').click(function(e) {
      e.preventDefault();

      var src = $(this).find('img').attr('src');
      var src_set = $(this).find('img').attr('src-set');

      $('.image_holder img').attr('src',src);
      $('.image_holder img').attr('src-set',src_set);

      var img_parent = $('.image_holder').find('img').parent();
      img_parent.attr('href',src);
      img_parent.data('lightbox',$('.product_product_image').index($(this)));

      $('.product_product_image>div.active').removeClass('active');
      $(this).addClass('active');

      return false;
    });
  }

  function initJumpNav() {
    var jump_links = $('.jump_nav_link');

    jump_links.each(function(itm) {
      var direction = $(this).data('direction');

      if(direction == 'down') {
        if(jump_links.index(this) < jump_links.length - 1) {
          $(this).show();
        }
      } else if(direction == 'up') {
        if(jump_links.index(this) > 0) {
          $(this).show();
        }
      }
    });

    $(document).on('touchstart click','.jump_nav_link',function(e) {
      e.preventDefault();
      var direction = $(this).data('direction');
      var idx = jump_links.index(this);

      if(direction == 'down') {
        scrollTo(jump_links.eq(idx+1).parents('.jump_nav_group'));
      } else if(direction == 'up') {

        if((idx-1) == 1) {
          scrollTo(null);
        }
        else {
         scrollTo(jump_links.eq(idx-1).parents('.jump_nav_group'));
       }
     }
   });
  }

  function scrollTo($el) {

    if($el == null) {

      $('html, body').animate({

        scrollTop: 0
      }, 750);
      return;
    }

// if ( $('.sub-nav').length ){
//   var spacer = $('.spacer').outerHeight() + $('.sub-nav').height();
// } else {
// }
var spacer = $('.spacer').outerHeight();

$('html, body').animate({
  scrollTop: $el.offset().top - spacer
}, 350);
}

var map;

function initialize() {
  if($('#map').length == 0) return;

  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 9,
    center: new google.maps.LatLng(latitude,longitude),
    mapTypeId: 'roadmap',
    animation: google.maps.Animation.DROP,
    scrollwheel:  false,
    styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]}]
  });

  addMarker({
    position: new google.maps.LatLng(latitude,longitude),
    type: 'info',
    animation: google.maps.Animation.DROP,
  })

}

function addMarker(feature) {
  var marker = new google.maps.Marker({
    position: feature.position,
    animation: google.maps.Animation.DROP,
    icon: new google.maps.MarkerImage(icon, null, null, null, new google.maps.Size(36,52)),
    map: map
  });
}


var bannerTimeOut = setTimeout(function() {
 $(".banner-menu").addClass('actives');
 $(".mobile_menu").addClass('banner-active');
 $(".spacer").addClass('active-sp');

},2000);
var Cookie = {

  init: function() {
    if (typeof(Storage) === "undefined") {
      console.error('Storage is required for cookie notice to work');
    }
    this.loadData();

  },

  loadData: function() {
    var data = JSON.parse(localStorage.getItem('cookie_notice',data));
    if(data) {
      Cookie.updateCart(data);
    } else {
      Cookie.storeData();
    }
  },
  updateCart: function(cookie_notice) {
    if(cookie_notice.cookie == 1) {
      clearTimeout(bannerTimeOut);
      $(".banner-menu").removeClass('actives');
      $(".mobile_menu").removeClass('banner-active');
      $(".spacer").removeClass('active-sp');
      $(".banner-cookie p").remove();
      $(".banner-cookie h3").remove();
    }
    else {
      $(".banner-menu").addClass('actives');
      $(".mobile_menu").addClass('banner-active');
      $(".spacer").addClass('active-sp');
    }
  },
  storeData: function() {
    var data = JSON.stringify({"cookie":1});
    localStorage.setItem('cookie_notice',data);
  },
}

